import React from "react";
import "./Messagebox.css";
import { TiMessages } from "react-icons/ti";

import { useState, useEffect } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { motion } from "framer-motion";

function Messagebox() {
  const steps = [
    {
      id: "Greet",
      message: "Hello, How can we help you?",
      trigger: "Done",
    },
    {
      id: "Done",
      message: "Please enter your name!",
      trigger: "waiting1",
    },
    {
      id: "waiting1",
      user: true,
      trigger: "Name",
    },
    {
      id: "Name",
      message: "Hi {previousValue}, Please select your issue",
      trigger: "issues",
    },
    {
      id: "issues",
      options: [
        {
          value: "React",
          label: "React",
          trigger: "React",
        },
        { value: "Angular", label: "Angular", trigger: "Angular" },
      ],
    },
    {
      id: "React",
      message:
        "Thanks for letting your React issue, Our team will resolve your issue ASAP",
      end: true,
    },
    {
      id: "Angular",
      message:
        "Thanks for letting your Angular issue, Our team will resolve your issue ASAP",
      end: true,
    },
  ];
  const [toggle, settoggle] = useState(false);
  const [show, setshow] = useState(false);
  const [texts, settexts] = useState("");
  const [input, setinput] = useState([
    {
      texts: "Hello",
    },
  ]);
  useEffect(() => {
    setTimeout(() => {
      // setshow(true);
    }, 3000);
  }, []);
  const submitdata = () => {
    const items = { texts };
    setinput([...input, items]);
  };

  return (
    <div>
      {show && (
        <div className="messagebox_fixed_chatapp">
          {/* <div className="messagebox_header">
            <h5>ChatApp</h5>
            <span
              class="material-symbols-outlined icons"
              onClick={() => setshow(false)}
            >
              close
            </span>
          </div> */}
          {/* <ul className="messagebox_content">
            <li className="messagebox_text">
              <span class="material-symbols-outlined">person</span>
              <p>Hello!How could we help you?</p>
            </li>
            <li className="messagebox_text_1">
              <p>Helloo</p>
            </li>
          </ul>
          <div className="messagebox_textarea">
            <textarea
              name=""
              id=""
              placeholder="Type something and hit enter"
              cols="17"
              rows="5"
              required
            ></textarea>
            <span class="material-symbols-outlined">send</span>
          </div> */}
          {/* <ChatBot headerTitle="Chatbot😑" steps={steps} /> */}
        </div>
      )}

      <div className="Messagebox_fixed" onClick={() => setshow(!show)}>
        {show ? (
          <span class="material-symbols-outlined icons">close</span>
        ) : (
          <TiMessages color="white" size={27} />
        )}
      </div>
    </div>
  );
}

export default Messagebox;
