import React from "react";
import "./Address.css";
import { AiFillPhone } from "react-icons/ai";
import { AiOutlineMail, AiOutlineWhatsApp } from "react-icons/ai";

import { MdOutlineCall } from "react-icons/md";

import { SlCallEnd } from "react-icons/sl";

import { GiEarthAfricaEurope } from "react-icons/gi";

import { HiOutlineLocationMarker } from "react-icons/hi";

function Address() {
  return (
    <div className="Address_flex" id="Contact">
      <div className="Address_flex_item_1_a">
        <iframe
          title="Infos"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.1560705227002!2d80.16650257494435!3d13.025731487294635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5261ac7ac8d47f%3A0x7a6a140163e392e!2sMayil%20Veera%20Tech%20Solutions%20and%20Consultancy!5e0!3m2!1sen!2sin!4v1689167676842!5m2!1sen!2sin"
          className="Map"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="Address_flex_item_2_a">
        <h1>FIND US</h1>
        <p />
        <div className="Address_grid">
          <div className="Address_grid_flex_item_1">
            <HiOutlineLocationMarker color="orange" size={35} />

            <div className="Address_grid_flex_item_1_1">
              <div className="Address_grid_flex_item_text">Location</div>
              <div>72 First Cross Street Sabari Nagar,</div>

              <div>porur,chennai</div>
            </div>
          </div>
          <div className="Address_grid_flex_item_1">
            <AiOutlineWhatsApp color="orange" size={35} />

            <div className="Address_grid_flex_item_1_1">
              <div className="Address_grid_flex_item_text">Whatsapp</div>

              <a href="https://api.whatsapp.com/send?phone=8675854895">
                {" "}
                +91 72005 41161
              </a>
              {/* <a href="https://wa.me/8675854895"> 72005 41161</a> */}
              {/* </div> */}
            </div>
          </div>

          <div className="Address_grid_flex_item_1">
            <GiEarthAfricaEurope color="orange" size={35} />
            <div className="Address_grid_flex_item_1_1">
              <div className="Address_grid_flex_item_text">Email</div>
              {/* <div>www.mayilveera.com,</div> */}
              <div>info@mayilveera.com</div>
            </div>
          </div>
          <div className="Address_grid_flex_item_1">
            <SlCallEnd color="orange" size={35} />
            <div className="Address_grid_flex_item_1_1">
              <div className="Address_grid_flex_item_text">call</div>
              <div>
                <a href="tel:8675854895" target="_blank" rel="noreferrer">
                  +91 72005 41161
                </a>
                {/* <a href="tel:8882192787">Call us at 888-219-2787</a>/ */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Address;
