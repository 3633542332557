import React from "react";
import { motion } from "framer-motion";
import Image from "../../assets/ai-monitoring-desktop-business-man.jpg";
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillLinkedin,
} from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";

import "./Header.css";

function Header() {
  return (
    <div className="Header_flex">
      <div className="Header">
        <div className="Headerimage_absolute">
          <motion.div
            className="Headerimage_absolute_title_header_1"
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: -1500 }}
            transition={{ duration: 2 }}
          >
            Provider of Digital Services and Solutions
          </motion.div>

          <div className="Headerimage_absolute_title">
            <motion.div
              animate={{ opacity: 1, x: 0 }}
              initial={{ opacity: 0, x: -1500 }}
              transition={{ duration: 2 }}
              className="Headerimage_absolute_title"
            >
              Transforming Industries with the power of IoT, AI, ML, and DL
            </motion.div>

            <button>
              <a href="#Products">See Our Products</a>
            </button>
            {/* <p>
              Transforming Industries with the power of{" "}
              <span className="Flliping_text_sub">IOT,AI,ML and DL</span>
            </p> */}
            {/* <div className="Flipping_text">
              <span className="flippingtext_header">IoT</span>
              <span className="flippingtext_header">AI</span>
              <span className="flippingtext_header">ML</span>
              <span className="flippingtext_header">DL</span>
              <span className="flippingtext_header">IoT</span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="Header_icons_flex">
        <div className="Header_icons_1">
          <FaFacebook color="black" size={25} />
        </div>
      </div>
      <div className="Header_icons_flex_1">
        <div className="Header_icons_2">
          <AiOutlineTwitter color="black" size={25} />
        </div>
      </div>
      <a
        href="https://www.linkedin.com/in/mayilveera-tech-solutions-4b188b33b/"
        target=" _blank"
        className="Header_icons_flex_2"
      >
        <div className="Header_icons_3">
          <AiFillLinkedin color="black" size={25} />
        </div>
      </a>
    </div>
  );
}

export default Header;
