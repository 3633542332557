import React from "react";
import {
  CTA,
  Brand,
  Navbar,
  Address,
  Header,
  Spiliter,
  Aboutus,
} from "./components";

import {
  Blog,
  Possibility,
  Whatis,
  Features,
  Footer,
  Forms,
  Messagebox,
  Onthetop,
} from "./containers";
function files() {
  return (
    <div>
      <div>
        <div id="home">
          <Navbar />
          <Spiliter />
          <Header />
        </div>

        <Spiliter />
      </div>
      <Whatis />
      <Brand />
      <Features />
      <Aboutus />
      <Forms />

      <Address />
      <Spiliter />
      <Onthetop />
      <Footer />
    </div>
  );
}

export default files;
