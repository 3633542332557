import Google from "../../assets/google.png";
import Atlassian from "../../assets/atlassian.png";
import Slack from "../../assets/slack.png";
import Dropbox from "../../assets/dropbox.png";
import Shopify from "../../assets/shopify.png";
import Linux from "../../assets/Linux.png";
import Yocto from "../../assets/yocto_1.png";
import Golong from "../../assets/Go.png";
import Openembedded from "../../assets/openembedded (1).png";
import kafka from "../../assets/kafka.png";
import NXP from "../../assets/nxp.png";
import Microchip from "../../assets/imgonline-com-ua-resize-J8k5ezQd08aQES.png";

export {
  Google,
  Atlassian,
  Shopify,
  Dropbox,
  Slack,
  Linux,
  Yocto,
  Golong,
  Openembedded,
  kafka,
  NXP,
  Microchip
};
