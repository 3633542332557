import React from "react";
import "./Features.css";
import AIIMAGE1 from "../../assets/IoT.jpg";
import AIIMAGE2 from "../../assets/web-hosting-software-functions.jpg";
import AIIMAGE3 from "../../assets/web-hosting-software-functions.jpg";
function Feaatures() {
  return (
    <div id="services">
      <div className="features-flex">
        <span>SERVICES</span>
        {/* <div>Everything you need in a modern bank and more.</div> */}
      </div>
      <div className="fearures-flex section__padding">
        <img
          className="features-img"
          src={AIIMAGE1}
          alt=""
          data-aos="fade-right"
          data-aos-duration="5000"
          data-aos-delay="500"
        />
        <div
          className="features-content"
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-delay="500"
        >
          {/* <div className="features-icons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
              />
            </svg>
          </div> */}
          <h5>IoT and Embedded Systems</h5>
          <p>
            At MVTSC,We offer you the opportunity to leverage our experience and
            expertise, our knowledge and our innovation to power your device
            launch, relaunch, update, or upgrade. From hardware to the cloud,
            our expert software team will help you design, develop and integrate
            a powerful software system tailored to your needs.We deliver the IoT
            embedded software solutions that meet your strategic and operational
            needs in industries.
            {/* We offer you the opportunity to leverage our experience
            and expertise, our knowledge and our innovation to power your device
            launch, relaunch, update, or upgrade. Whether designing and
            architecting a new device, migrating existing code, or optimizing
            and updating a code on a device already on the market, our teams
            know how to deliver the IoT software solutions and embedded solution
            you need to meet your strategic and operational needs in industries
            that include aeronautics, automotive, handheld and mobile, and
            consumer goods. */}
          </p>
        </div>
      </div>
      <div className="fearures-flex_1 section__padding">
        {/* <div
          className="features-content"
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="500"
        >
          <h5>Web Apps and Hosting</h5>
          <p>
            MVTSC is one of the fastest growing web hosting service provider.
            Our product portfolio includes - Domain Registration, Email Services
            and Web hosting services.From prototyping an idea to launching the
            web portal with enterprise-grade features — we will take care of the
            entire nine yards of custom web development for your business.
          </p>
        </div> */}
        {/* <img
          className="features-img"
          src={AIIMAGE2}
          alt=""
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-delay="500"
        /> */}
      </div>
      {/* <div className="fearures-flex_2 section__padding">
        <img
          className="features-img"
          src={AIIMAGE3}
          alt=""
          data-aos="fade-right"
          data-aos-duration="2000"
          data-aos-delay="500"
        />
        <div
          className="features-content"
          data-aos="fade-left"
          data-aos-duration="2000"
          data-aos-delay="500"
        >
          <div className="features-icons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <h5>ROBOTICS</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde alias
            sint quos? Accusantium a fugiat porro reiciendis saepe quibusdam
            debitis ducimus.
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default Feaatures;
