import React from "react";
import Image from "../../assets/ai-monitoring-desktop-business-man.jpg";
import "./Aboutus.css";
import { HiUserGroup } from "react-icons/hi";

function Aboutus() {
  return (
    <div className="AboutUS_header" id="Aboutus">
      <div>ABOUTUS</div>
      <p>
        <HiUserGroup color="#1f727e" size={75} />
      </p>
      <span>
        MVTSC is one of the fastest growing software products and services
        company that delivers a differentiated customer experience, accelerates
        cycle times, and helps customers in achieving business outcomes through
        adoption of new-age digital technologies and integrating them with their
        existing systems. Our vision is to transform industries with the power
        of IoT, Artifical Intelligence, Machine Learning and Deep Learning.
      </span>
    </div>
  );
}

export default Aboutus;
