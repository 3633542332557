export { default as Article } from "./article/article.jsx";
export { default as Navbar } from "./Navbar/Navbar.jsx";
export { default as CTA } from "./cta/Cta.jsx";
export { default as Feature } from "./Feature/Feature.jsx";
export { default as Brand } from "./Brand/Brand.jsx";
export { default as Address } from "./Address/Address.jsx";
export { default as Header } from "./Header/Header.jsx";
export { default as Spiliter } from "./Spiliter/Spiliter.jsx";
export { default as Aboutus } from "./Aboutus/Aboutus.jsx";
export { default as Ecommerce } from "./Ecommerce/Ecommerce.js";
export { default as Individualproduct } from "./Individualproduct/Individualproduct.js";
export {default as Pagenotavailable} from "./Pagenotavailable/Pagenotavaialble.jsx"
