import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shoppingservice from "./shoppingservice";
const initialState = {
  data: [],
  loading: false,
};
//
// async;
export const shoppingcartdatafetching = createAsyncThunk(
  "shopping/fetch",
  async (thunkApi) => {
    try {
      console.log("YESSSSSSSSS");
      return await shoppingservice.fetcingdata();
    } catch (error) {}
  }
);
export const shoppingcartslice = createSlice({
  name: "shoppingcart",
  initialState,
  reducers: {
    clearCart: (state) => {
      state.data = [];
    },
  },
  extraReducers: (buider) => {
    buider.addCase(shoppingcartdatafetching.pending, (state) => {
      state.data = [];
      state.loading = true;
    });
    buider.addCase(shoppingcartdatafetching.fulfilled, (state, action) => {
      state.data = action.payload.products;
      state.loading = false;
    });
    buider.addCase(shoppingcartdatafetching.rejected, (state) => {
      state.data = [];
      state.loading = true;
    });
  },
});

export default shoppingcartslice.reducer;
