import React from "react";
import { useState } from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Forms.css";
import AIIMAGE3 from "../../assets/socialmedia.png";
function Forms() {
  const formref = useRef();
  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const numberInputRef = useRef(null);
  const addinputref = useRef(null);

  const [form, setform] = useState({
    user_name: "",
    user_email: "",
    user_contactnumber: "",
    message: "",
  });
  const [notification, setnotification] = useState(false);
  const onChange = (e) => {
    const { name, value } = e.target;
    setform({ ...form, [name]: value });
    console.log(form);
  };
  const formsubmit = (e) => {
    e.preventDefault();
    console.log(form);
    if (
      nameInputRef.current.value.length === 0 ||
      emailInputRef.current.value.length === 0 ||
      numberInputRef.current.value.length === 0 ||
      addinputref.current.value.length === 0
    ) {
      alert("Please fill all the details");
    } else {
      console.log(formref.current.value);
      console.log(nameInputRef.current.value);
      console.log(emailInputRef.current.value);
      console.log(numberInputRef.current.value);
      console.log(addinputref.current.value);
      emailjs
        .sendForm(
          "service_yo0oubl",
          "template_4gp6qcx",
          formref.current,
          "YooYav4c6g6mm26_z"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      // setform({
      //   name: "",
      //   Email: "",
      //   contactnumber: "",
      //   texts: "",
      // });
      formref.current.reset();
      setnotification(true);
      setTimeout(() => {
        setnotification(false);
      }, 3000);
    }
  };

  return (
    <div>
      <div className="Form_flex_container" id="About">
        <div className="Form_item_grid">
          <h3>Signup Here! To Getin touch with us</h3>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
          <form ref={formref} onSubmit={formsubmit} className="form_grid">
            <div>
              <label className="Form_block" htmlFor="Firstname">
                Firstname:
              </label>
              <input
                ref={nameInputRef}
                placeholder="Your name"
                type="text"
                name="user_name"
                id="Firstname"
              />
            </div>
            <div>
              <label className="Form_block" htmlFor="Email">
                Email:
              </label>
              <input
                type="email"
                ref={emailInputRef}
                placeholder="Your email"
                name="user_email"
                id="Email"
              />
            </div>
            <div>
              <label className="Form_block" htmlFor="number">
                Your Contact number:
              </label>
              <input
                type="number"
                name="user_contactnumber"
                ref={numberInputRef}
                id="number"
              />
            </div>
            <div>
              <label className="Form_block" htmlFor="text">
                Do you want add anything?
              </label>
              <input type="text" ref={addinputref} name="message" id="text" />
            </div>

            {/* <div>
              <label className="Form_block" htmlFor="Drop-down">
                How do you know about us?
              </label>
              <select id="Drop-down">
                <option value="Facebook">Facebook</option>
                <option value="Youtube">Youtube</option>
                <option value="Twitter">Twitter</option>
                <option value="Newspaper">Newspaper</option>
                <option value="Linkedin">Linkedin</option>
              </select>
            </div> */}
            {notification && (
              <p>
                <span className="icon_right">
                  <ion-icon name="checkmark-outline"></ion-icon>
                </span>
                We receiced your deatils! we will reach you soon
              </p>
            )}
            <div className="button_flex">
              <button type="submit">Signup Here</button>
            </div>
          </form>
        </div>
        <div className="Form_img">{/* <img src={AIIMAGE3} alt="" /> */}</div>
      </div>
    </div>
  );
}

export default Forms;
