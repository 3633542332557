import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Pagenotavailable.css"; 
const Pagenotavaialble = () => {
  useEffect(() => {
   
    document.title = "Page Not Available | Mayilveera Tech Solutions";

    
    return () => {
      document.title = "Mayilveera Tech Solutions and consultancy";
    };
  }, []);

  return (
    <div className="page-not-available-container">
      <div className="content">
        <h1 className="error-code">404</h1>
        <h2 className="message">
          Oops! The page you’re looking for is not available.
        </h2>
        <p className="description">
          We couldn't find the page you were looking for. It might have been
          moved or deleted.
        </p>
        <Link to="/" className="home-link">
          Go back to Home
        </Link>
      </div>
    </div>
  );
};

export default Pagenotavaialble;
