import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
function Individualproduct() {
  const [data, setdata] = useState([]);
  const params = useParams();
  const API = `https://dummyjson.com/products/${params.id}`;
  const fetcingdata = async () => {
    try {
      const response = await axios.get(API);
      console.log(response.data);
      setdata(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetcingdata();
  }, []);
  return (
    <div>
      <div>Individualproduct {params.id}</div>

      <div>{data.title} </div>

      <img src={data.thumbnail} alt={data.title} />
    </div>
  );
}

export default Individualproduct;
