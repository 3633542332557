import React from "react";
import "./Navbar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";

import Apple from "../../assets/Mvtsclogo.png";
import { motion } from "framer-motion";
function Navbar() {
  const [toggle, settoggle] = useState(false);
  const variants = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const items = {
    hidden: {
      x: 50,
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
      },
    },
  };
  const togglefunc = () => {
    if (toggle) {
      document.body.style.overflowY = "visible";
    } else {
      document.body.style.overflowY = "hidden";
    }

    settoggle(!toggle);
  };
  return (
    <div className="project_Navbar">
      <div className="project_Navbar_links">
        <div className="project_Navbar_img">
          <img src={Apple} alt="" />
        </div>
      </div>
      <div className="project_Navbar_signin">
        <div className="project_Navbar_container">
          <p>
            <a href="#home">Home</a>
          </p>
          <p>
            <a href="#Products">Products</a>
          </p>
          <p>
            <a href="#services">Services</a>
          </p>
          <p>
            <a href="#Aboutus">About Us</a>
          </p>
          <p>
            <a href="#Contact">Contact</a>
          </p>
          <abbr title="Shoppingcart">
            <div>
              <Link to="/">
                <AiOutlineShoppingCart size={27} color="grey" />
              </Link>
            </div>
          </abbr>
        </div>
      </div>
      <div className="project_Navbar_icon">
        {toggle ? (
          <FaTimes onClick={togglefunc} color="grey" />
        ) : (
          <FaBars size={25} color="grey" onClick={togglefunc} />
        )}
        {toggle && (
          <motion.div
            className="project_Navbar_container_Responsive"
            initial={{ opacity: 0 }}
            animate={{ x: toggle ? -100 : 0, opacity: 1, duration: 0.5 }}
            transition={{
              duration: 0.5,
            }}
          >
            <motion.div
              variants={variants}
              initial="hidden"
              animate="show"
              className="project_Navbar_container_links"
            >
              <div className="icon_static">
                <FaTimes onClick={togglefunc} color="grey" />
              </div>
              <motion.div onClick={togglefunc} variants={items}>
                {" "}
                <p>
                  <a href="#home">Home</a>
                </p>
              </motion.div>
              <span></span>
              <motion.div onClick={togglefunc} variants={items}>
                {" "}
                <p>
                  <a href="#Products">Products</a>
                </p>
              </motion.div>
              <span></span>
              <motion.div onClick={togglefunc} variants={items}>
                {" "}
                <p>
                  <a href="#services">Services</a>
                </p>
              </motion.div>
              <span></span>
              <motion.div onClick={togglefunc} variants={items}>
                <p>
                  <a href="#Aboutus">About Us</a>
                </p>
              </motion.div>
              <span></span>
              <motion.div onClick={togglefunc} variants={items}>
                {" "}
                <p>
                  <a href="#Contact">Contact</a>
                </p>
              </motion.div>
              <span></span>
            </motion.div>
            <div className="Project_Navbar_links_signin">
              {/* <p>Signin</p>
              <button>Signup</button> */}
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
