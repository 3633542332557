import axios from "axios";

const API = "https://dummyjson.com/products";

//fetching data

const fetcingdata = async () => {
  const response = await axios.get(API);
  console.log(response.data);

  return response.data;
};

const shoppingservice = {
  fetcingdata,
};

export default shoppingservice;
