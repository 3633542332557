import React from "react";
import { Link } from "react-router-dom";
import "./Ecommerce.css";
import { useSelector, useDispatch } from "react-redux";
import { shoppingcartdatafetching } from "../../Redux/shoppingcart/shoppingcartslice";
import { useEffect } from "react";
function Ecommerce() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.shoppingcart);
  useEffect(() => {
    dispatch(shoppingcartdatafetching());
  }, []);

  return (
    <div>
      <div>
        <div className="shoppingcart_search_container">
          <div className="shoppingcart_search_item">
            <input type="text" placeholder="Search" />

            <button>Search</button>
          </div>
        </div>
      </div>
      <div className="container_all">
        {data.map((product) => {
          return (
            <div>
              <Link to={`/Shoppingitem/${product.id}`}>
                <div className="shipping_indivdualbox">
                  <div>
                    <img src={product.thumbnail} alt={product.title} />
                  </div>
                  <p>{product.title}</p>
                  <p>${product.price}</p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Ecommerce;
