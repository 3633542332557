import React from "react";
import "./Footer.css";
import { FaFacebook, FaYoutube, FaLinkedin } from "react-icons/fa";
// import {
//   BiLogoFacebookCircle,
//   BiLogoYoutube,
//   BiLogoLinkedinSquare,
// } from "react-icons/bi";
import { AiFillInstagram, AiFillTwitterCircle } from "react-icons/ai";
import { RiInstagramFill } from "react-icons/ri";
function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="footer_flex_container">
          <div className="footer_item">
            <h4>Customer policy</h4>
            <ul>
              <li>
                <a href="#">Terms and conditions</a>
              </li>
              <li>
                <a href="">Privacy policy</a>
              </li>
              <li>
                <a href="">Cancellation</a>
              </li>
              <li>
                <a href="">Return</a>
              </li>
              <li>
                <a href="">Contact</a>
              </li>
            </ul>
          </div>

          <div className="footer_item">
            <h4>Carrers</h4>
            <ul>
              {/* <li>
                <a href="#">Communication and shopping</a>
              </li>
              <li>
                <a href="">IOT Gateway</a>
              </li> */}
              {/* <li>
                <a href="mailto:info@mayilveera.com">info@mayilveera.com</a>
              </li>
              <li>
                <a href="mailto:johndoe@fakeemail.com">Example mailto link</a>
              </li> */}
              <li>
                <a
                  href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@mayilveera.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  info@mayilveera.com
                </a>
              </li>
            </ul>
          </div>

          <div className="footer_item">
            <h4>Support</h4>
            <ul>
              <li>
                <a href="#">Technical support</a>
              </li>
              {/* <li>
                <a href="">Basic support</a>
              </li>
              <li>
                <a href="">software support</a>
              </li> */}
              <li>
                <a href="">Where to buy</a>
              </li>
            </ul>
          </div>

          <div className="footer_item">
            <h4>Products and services</h4>
            <ul>
              <li>
                <a href="#">IOT Gateway</a>
              </li>
              <li>
                <a href="#">Protocal Stacks</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="Footer_flex_bottom">
          <div>
            ©Mayilveera Tech Solutions and counsultancy 2021, All Rights
            Reserved.
          </div>
          <div>
            {/* <FaFacebook />
            <FaLinkedin />
            <FaYoutube />
            <AiFillTwitterCircle />
            <AiFillInstagram /> */}
          </div>
          <div>
            {/* <ul className="Footer_list_flex">
              <li>
                <a href="">Sitemap</a>
              </li>
              <li>
                <a href="https://www.google.com/">Termsofuse</a>
              </li>
              <li>
                <a href="">Privacynote</a>
              </li>
              <li>
                <a href="">Cookiepolicy</a>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
