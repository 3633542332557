import React from "react";
import "./Onthetop.css";
import { useState } from "react";
function Onthetop() {
  const [show, setshow] = useState(false)
  const showonthetopbutton = () => {
    console.log(window.screenY)
    if(window.scrollY >= 700){
      setshow(true)
    }else{
      setshow(false)
    }
  }
  window.addEventListener("scroll", showonthetopbutton)
  return (
    <div>
      {show && <a href="#home" className="Onthetoparrow">
        <ion-icon className="arrow" name="arrow-up-outline"></ion-icon>
      </a>}
      
    </div>
  );
}

export default Onthetop;
