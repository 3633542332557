import React from "react";
import "./Whatsis.css";
import AIIMage1 from "../../assets/download.png";
import AIIMAGE from "../../assets/opc-ua-foundation-logo.png";
import AIIMAGE2 from "../../assets/nanopi.jpeg";
import { BiRightArrowAlt } from "react-icons/bi";
function Whatis() {
  return (
    <div style={{ marginTop: "55px" }} id="Products">
      <div className="whatwe_text">PRODUCTS</div>
      <div className="Whatwedo_flex">
        <div
          className="Whatwedo_flex_item1"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="500"
        >
          <div className="whatwedo_overflow">
            <div className="Whatwedo_image_wrapper">
              <div className="Whatwedo_image-container">
                <img className="Whatwedo_image" src={AIIMage1} alt="" />
              </div>
            </div>
          </div>
          <div className="whatwedo_content">
            <h4>Modbus Master Stack And Modbus Slave Stack</h4>
            <span>
              Modbus is one of the most widely used industrial protocol stacks.
              MayilVeera offers Modbus protocol Stack for both use in server and
              client configurations
            </span>
            <div className="whatwedo_icon_wrapper">
              <span>
                <BiRightArrowAlt color="gray" size={50} />
              </span>
            </div>
          </div>
        </div>
        <div
          className="Whatwedo_flex_item1"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="900"
        >
          <div className="whatwedo_overflow">
            <div className="Whatwedo_image_wrapper">
              <div className="Whatwedo_image-container">
                <img className="Whatwedo_image" src={AIIMAGE} alt="" />
              </div>
            </div>
          </div>
          <div className="whatwedo_content">
            <h4>OPC UA Server and Client stack </h4>
            <span>
              OPC UA technologies allow information to be easily and securely
              exchanged between diverse platforms. MayilVeera offers OPC UA
              protocol Stack for both use in server and client configurations
            </span>
            <div className="whatwedo_icon_wrapper">
              <span>
                <BiRightArrowAlt color="gray" size={50} />
              </span>
            </div>
          </div>
        </div>
        <div
          className="Whatwedo_flex_item1"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="1200"
        >
          <div className="whatwedo_overflow">
            <div className="Whatwedo_image_wrapper">
              <div className="Whatwedo_image-container">
                <img className="Whatwedo_image" src={AIIMAGE2} alt="" />
              </div>
            </div>
          </div>
          <div className="whatwedo_content">
            <h4>IOT Gateway </h4>
            <span>
              IoT gateway is a physical device that connects sensors, IoT
              modules, and smart devices to the cloud. MayilVeera offers IoT
              gateway products for RS485, TCP/IP, WiFi.
            </span>
            <div className="whatwedo_icon_wrapper">
              <span>
                <BiRightArrowAlt color="gray" size={50} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Whatis;
